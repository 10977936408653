import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
  height: 90%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: #2E3638;
  margin-top: 20px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0px 0px 10px;
`

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: #FCB520;
  border-radius: 5px;
`

export const Text = styled.h1`
  font-size: 12px;
  margin-top: 10px;
  color: #090909;
`

export const Image = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`

export const Logo = styled.img`
  position: absolute;
  top: 390px;
  left: 20px;
  margin-top: 10px;
  max-width: 80px;
  max-height: 30px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`