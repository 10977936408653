import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Transition } from 'react-transition-group';

import logo from '../../assets/logo.png';
import selo from '../../assets/selo.png';
import api from '../../services/api';

import Product from './Product';

import { Container, Image, Title } from './styles';

function Home({ in: inProp }) {
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const duration = 300;
  let { id } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    await api.get(`/products/details/${id}`).then(res => {
      setTimeout(() => setVisible(true), 3000);
      setData(res.data);
    }).catch(err => {
      setTimeout(() => setVisible(true), 3000);
      setNotFound(true);
    })
  }

  // if (!visible) {
  //   return (
  //     <Transition in={inProp} timeout={duration}>
  //       <Container style={{ justifyContent: 'center' }}>
  //         <Image
  //           src={logo}
  //         />
  //       </Container>
  //     </Transition>
  //   )
  // }

  if (notFound) {
    return (
      <Transition in={inProp} timeout={duration}>

        <Container style={{ justifyContent: 'center' }}>
          <Image
            src={logo}
            style={{ position: 'absolute', top: 20 }}
          />
          <Title>Oops, não encontramos seu produto!</Title>
        </Container>
      </Transition>
    )
  }

  return (
    <Container>
      <Image
        src={logo}
      />
      {data.DESCRICAO_ITEM !== undefined && (
        <Product
          data={data}
          descricao={data.DESCRICAO_ITEM}
          descricao_tecnica={data.OBS_TECNICA}
          ref_tantto={data.REF_TANTTO}
          cod={data.CODITEM}
          imagem_produto={data.PATH_IMG_PECA}
          imagem_veiculo={data.PATH_IMG_VEICULO}
          oem={data.REF_OEM}
        />
      )}
    </Container>
  );
}

export default Home;