import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #4d4d4f;
`

export const Image = styled.img`
  max-width: 260px;
  max-height: 80px;
`


export const Title = styled.h4`
  color: #FFF;
`