import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import './styles.css'

import Home from './pages/Home'

function App() {
  return (
    <BrowserRouter>
      <Route component={Home} exact path='/product/:id'/>
    </BrowserRouter>
  );
}

export default App;
