import React from 'react';
import { FiChevronDown, FiCheck } from 'react-icons/fi'
import { Container, Row, Box, Text, Column, Image, Logo } from './styles';

import logo3 from '../../../assets/logo3.png';
import selo from '../../../assets/selo.png';

function Product({ cod, descricao, descricao_tecnica, ref_tantto, imagem_produto, imagem_veiculo, oem }) {
  return (
    <Container>
      <Row style={{ justifyContent: 'space-between', padding: 10}}>
        <Box>
          <FiCheck
            size={20}
            color='#000'
          />
        </Box>
        <Text style={{ color: '#F5F6F7', marginTop: 0, fontSize: 14 }}>Informações sobre o produto</Text>
        <FiChevronDown
          size={20}
          color='#04080B'
        />
      </Row>
      <Column style={{ justifyContent: 'flex-start', backgroundColor: '#FFF', width: '95%', alignSelf: 'center', marginTop: 10, marginBottom: 10, paddingBottom: 30, borderRadius: 3 }}>
        <Row style={{ display: 'flex', flex: 1 }}>
          <Column style={{ flex: 1 }}>
          <Image
            src={selo}
            style={{ position: 'absolute', top: 180, left: 20, width: 40, height: 40 }}
          />
            <Image
              src={imagem_veiculo}
              style={{ marginTop: 20 }}
            />
            <Image
              src={imagem_produto}
            />
            <Logo
              src={logo3}
            />
          </Column>
          <Column style={{ flex: 2 }}>
            <Text style={{ color: '#CB3638', textAlign: 'center', width: '100%' }}>{ref_tantto}</Text>
            <Text style={{ textAlign: 'center', width: '100%' }}>{oem}</Text>
            <Text>{descricao}</Text>
            <Text style={{ width: '100%' }}>{descricao_tecnica}</Text>
          </Column>
        </Row>
      </Column>
    </Container>
  );
}

export default Product;